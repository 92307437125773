<template>
  <div id="app" class="bg-dark">
    <router-view />
  </div>
</template>

<script>
import broadcast from './common/broadcast'
import studyStatusChanges from './common/studyStatusChanges'
import webServices from './common/webServices'
import workflow from './common/workflow'
import worklistFields from './common/worklistFields.json'
import WorklistFilter from './common/worklistFilter'

export default {
  data() {
    return {
      inPrimaryWindow: false,
    }
  },
  created() {
    let hrefParts = window.location.href.split('?')
    let initRoute = (hrefParts.length > 0) ? hrefParts[0].replace(/^.*\//, "") : "unknown"
    this.inPrimaryWindow = (initRoute == "")
    this.$log.debug(`App uid=[${this.$store.state.uid}] inPrimaryWindow=${this.inPrimaryWindow}`)
    this.$log.debug("Adding broadcast channel listener.")
    broadcast.channel.onmessage = (event) => {
      this.handleMessage(event)
    }
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload)
    window.addEventListener("unload", this.handleWindowUnload)
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    window.removeEventListener("unload", this.handleWindowUnload)
  },
  mounted() {
    // +TODO+ Add wait for web_services if it is not responding.
    //
    studyStatusChanges.queueQuery()

    this.$log.debug("Querying server for locale (default browser value="+this.$store.state.locale+")")
    webServices.readSystemSetting("locale")
    .then(response => {
      if (response != null) {
        this.$log.debug("Found locale")
        this.$store.commit("changeLocale", response)
      }
      else {
        this.$log.debug("System locale null, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    this.$log.debug("Querying server for worklistActions")
    webServices.readUserSetting("worklistActions")
    .then(response => {
      if ((response != null) && (Array.isArray(response))) {
        this.$log.debug("Found worklistActions")
        this.$store.commit("changeWorklistActions", response)
      }
      else {
        this.$log.debug("Cached worklistActions empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    this.$log.debug("Querying server for worklistColumns")
    webServices.readUserSetting("worklistColumns")
    .then(response => {
      if ((response != null) && (Array.isArray(response))) {
        this.$log.debug("Found worklistColumns")
        this.$store.commit("changeWorklistColumns", response)
      }
      else {
        this.$log.debug("Cached worklistColumns empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    this.initColumnWidths()
    this.$log.debug("Querying server for worklistColumnWidths")
    webServices.readUserSetting("worklistColumnWidths")
    .then(response => {
      if ((response != null) && (Object.keys(response).length > 0)) {
        this.$log.debug("Found worklistColumnWidths")
        const keys = Object.keys(response)
        let columnWidths = this.$store.state.worklistColumnWidths
        for (var k = 0; k < keys.length; k++) {
          columnWidths[keys[k]] = response[keys[k]]
        }
        this.$store.commit("changeWorklistColumnWidths", columnWidths)
      }
      else {
        this.$log.debug("Cached worklistColumnWidths empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    this.$log.debug("Querying server for worklistViewers")
    webServices.readUserSetting("worklistViewers")
    .then(response => {
      if ((response != null) && (Object.keys(response).length > 0)) {
        this.$log.debug("Found worklistViewers")
        this.$store.commit("changeWorklistViewers", response)
      }
      else {
        this.$log.debug("Cached worklistViewers empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    this.$log.debug("Querying server for worklistAltViewers")
    webServices.readUserSetting("worklistAltViewers")
    .then(response => {
      if ((response != null) && (Array.isArray(response))) {
        this.$log.debug("Found worklistAltViewers")
        this.$store.commit("changeWorklistAltViewers", response)
      }
      else {
        this.$log.debug("Cached worklistAltViewers empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    this.$log.debug("Querying server for worklistPerPage")
    webServices.readUserSetting("worklistPerPage")
    .then(response => {
      if (response != null) {
        this.$log.debug("Found worklistPerPage "+response)
        this.$store.commit("changeWorklistPerPage", response)
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    this.$log.debug("Querying server for worklistRefreshInterval")
    webServices.readUserSetting("worklistRefreshInterval")
    .then(response => {
      if (response != null) {
        this.$log.debug("Found worklistRefreshInterval "+response)
        this.$store.commit("changeWorklistRefreshInterval", response)
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })
    
    // SP-474 - only do this if main/initial window.
    //
    if (this.inPrimaryWindow) {
      this.$log.debug("Querying server for worklistSettings")
      webServices.readUserSetting("worklistSettings")
      .then(response => {
        if ((response != null) && (Object.keys(response).length > 0)) {
          this.$log.debug("Found worklistSettings")
          this.$store.commit("changeWorklistSettings", response)
        }
        else {
          this.$log.debug("Cached worklistSettings empty, using default")
        }
        var worklistName = this.$store.state.worklistName
        if (worklistName == '') {
          worklistName = this.$store.getters.defaultWorklistName
          this.$log.debug("default worklist filter=["+worklistName+"]")
          this.$store.commit("changeWorklistName", worklistName)
        }
      })
      .catch(err => {
        this.$log.error("Error fetching worklistSettings, using default: "+err)
      })
      .finally(() => {
        this.$log.debug("Updating server with default ad hoc filter")
        let adhocWorklistFilter = new WorklistFilter(this.$store.state.adHocWorklistName)
        this.$log.debug(`worklistName=${adhocWorklistFilter.worklistName}`)
        this.$store.commit('changeWorklistFilter', adhocWorklistFilter)
        webServices.updateUserSetting("worklistSettings", this.$store.state.worklistSettings)
        .then(() => {
          this.$log.debug(`Initial read of worklist using filter=${adhocWorklistFilter.worklistName}`)
          this.$store.commit('changeWorklistName', adhocWorklistFilter.worklistName)
          webServices.readWorklist()
        })
        .catch(err => {
          this.$log.error("Error updating server worklistSettings: "+err)
        })
      })
    }
    
    this.$log.debug("Querying server for wwwlFavorites")
    webServices.readUserSetting("wwwlFavorites")
    .then(response => {
      if ((response != null) && (Array.isArray(response))) {
        this.$log.debug("Found wwwlFavorites")
        this.$store.commit("changeWwwlFavorites", response)
      }
      else {
        this.$log.debug("Cached wwwlFavorites empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })
    
    this.$log.debug("Querying server for reportPhraseLut")
    webServices.readUserSetting("reportPhraseLut")
    .then(response => {
      if ((response != null) && (Object.keys(response).length > 0)) {
        this.$log.debug("Found reportPhraseLut")
        this.$store.commit("changeReportPhraseLut", response)
      }
      else {
        this.$log.debug("Cached reportPhraseLut empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })
    
    this.$log.debug("Querying server for reportSettings")
    webServices.readUserSetting("reportSettings")
    .then(response => {
      if ((response != null) && (Object.keys(response).length > 0)) {
        this.$log.debug("Found reportSettings")
        this.$store.commit("changeReportSettings", response)
      }
      else {
        this.$log.debug("Cached reportSettings empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })
    
    this.$log.debug("Querying server for exportStudy")
    webServices.readUserSetting("exportStudy")
    .then(response => {
      if ((response != null) && (Object.keys(response).length > 0)) {
        this.$log.debug("Found exportStudy")
        this.$store.commit("changeExportStudy", response)
      }
      else {
        this.$log.debug("Cached exportStudy empty, using default")
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })
  },
  methods: {
    initColumnWidths() {
      let columnWidths = {}
      for (var i=0; i < worklistFields.data.length; i++) {
        const field = worklistFields.data[i]
        columnWidths[field.key] = -1
      }
      this.$log.debug(columnWidths)
      this.$store.commit("changeWorklistColumnWidths", columnWidths)
    },
    displayToast(message, variant) {
          this.$bvToast.toast(message, {
              autoHideDelay: 5000,
              solid: true,
              title: 'INSPIRE PACS',
              variant: variant,
          })
    },
    handleMessage(event) {
      try {
        const message = event.data
        if (message !== undefined) {
          this.$log.debug('received message type='+message['type'])
          if (message['type'] == broadcast.CLOSE_REPORT_WINDOW_MSG) {
            let windowUid = message['payload']
            if ((this.$store.state.reportWindows[windowUid] !== undefined) && !this.$store.state.reportWindows[windowUid].closed) {
              workflow.closeStudy(this.$store.getters.selectedStudyUid(windowUid), workflow.TARGET_REPORT_WINDOW, windowUid)
              .then(() => {
                this.$log.debug('close study successful for report')
              })
              .catch(err => {
                this.displayToast(err.message, 'warning')
              })
              this.saveReportWindowBox(windowUid)
              this.$store.state.reportWindows[windowUid].close()
              this.$store.commit('changeReportWindows', {
                'window': null,
                'windowUid': windowUid
              })
            }
          }
          else if (message['type'] == broadcast.CLOSE_STUDY_MSG) {
            workflow.closeStudy(message.payload.study_uid, message.payload.target, message.payload.window_uid)
            .then(() => {
              this.$log.debug("close study successful")
            })
            .catch(err => {
              this.displayToast(err.message, 'warning')
            })
          }
          else if (message['type'] == broadcast.CLOSE_VIEWER_WINDOW_MSG) {
            let windowUid = message['payload']
            if (this.$store.state.viewerWindows[windowUid] !== undefined) {
              workflow.closeStudy(this.$store.getters.selectedStudyUid(windowUid), workflow.TARGET_VIEWER, windowUid)
              .then(() => {
                this.$log.debug("close study successful for viewer")
              })
              .catch(err => {
                this.displayToast(err.message, 'warning')
              })
              this.saveViewerWindowBox(windowUid)
              this.$store.state.viewerWindows[windowUid].close()
              this.$store.commit('changeViewerWindows',  {
                'window': null,
                'windowUid': windowUid
              })
            }
          }
          else if (message['type'] == broadcast.NEXT_STUDY_UID_MSG) {
            let windowUid = message['payload']
            if (this.$store.state.viewerWindows[windowUid] !== undefined) {
              // Need to inform secondary windows change in selected study UID and next/previous study UID
              //
              let studyUid = this.$store.getters.nextStudyUid(windowUid)
              if (studyUid != null) {
                workflow.openStudy(studyUid, workflow.TARGET_VIEWER, windowUid)
                .then(() => {
                  let payload = {
                    studyUid: studyUid,
                    windowUid: windowUid
                  }
                  this.$store.commit("changeSelectedStudyUids", payload)
                  broadcast.postSelectedStudy(workflow.TARGET_VIEWER, windowUid)

                  if ((this.$store.state.reportWindows[windowUid] !== undefined) && !this.$store.state.reportWindows[windowUid].closed) {
                    workflow.openStudy(studyUid, workflow.TARGET_REPORT_WINDOW, windowUid)
                    .then(() => {
                      broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, windowUid)
                    })
                  }
                })
                .catch(err => {
                  this.displayToast(err.message, 'warning')
                })
              }
            }
          }
          else if (message['type'] == broadcast.OPEN_REPORT_WINDOW_MSG) {
            let reportWindowUid = message.payload.windowUid
            if (message.payload.studyUid != '') {
                  this.$store.commit("changeSelectedStudyUids", message.payload) // payload has same signature
            }
            if (message.payload.entry !== undefined) {
              this.$store.commit('addSecondaryWorklistEntry', message.payload.entry)
            }
            if ((this.$store.state.reportWindows[reportWindowUid] === undefined) || (this.$store.state.reportWindows[reportWindowUid].closed)) {
              let box = this.$store.state.reportWindowBox
              const windowOpts = 'popup=1,left='+box.x+',top='+box.y+',height='+box.h+',width='+box.w
              let reportWindowName = 'saincepacs_report_'+reportWindowUid
              this.$log.debug(`Opening report window reportWindowName=${reportWindowName}`)
              let reportWindow = window.open('/#/report?uid='+encodeURIComponent(reportWindowUid), reportWindowName, windowOpts)
              this.$log.debug(reportWindow)
              this.$store.commit('changeReportWindows', {
                'window': reportWindow, 
                'windowUid': reportWindowUid
              })
            }
            else {
              // Need to inform report window current worklist and study UID
              //
              let studyUid = this.$store.getters.selectedStudyUid(reportWindowUid)
              workflow.openStudy(studyUid, workflow.TARGET_REPORT_WINDOW, reportWindowUid)
              .then(() => {
                broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, reportWindowUid)
              })
              .catch(err => {
                this.displayToast(err.message, 'warning')
              })
              // Most browsers will block this.
              //
              this.$log.debug(`Requesting focus for report window reportWindowUid=${reportWindowUid}`)
              this.$store.state.reportWindows[reportWindowUid].focus()
            }
          }
          else if (message['type'] == broadcast.OPEN_VIEWER_WINDOW_MSG) {
            if (message.payload.entry !== undefined) {
              this.$log.debug(`Adding secondaryWorklist entry studyUid=${message.payload.entry.study_uid}`)
              this.$store.commit('addSecondaryWorklistEntry', message.payload.entry)
            }

            const viewerWindowsMax = this.$configs.maxViewerWindows || 10
            var viewerWindowsN = Object.keys(this.$store.state.viewerWindowsRoute).length
            this.$log.debug(`viewerWindowsN=${viewerWindowsN} viewerWindowsMax=${viewerWindowsMax}`)
            if (viewerWindowsMax <= viewerWindowsN) {
              this.displayToast(`You have reached the allowed limit for viewer windows (maximum=${viewerWindowsMax}).`, 'warning')
              return
            }
            
            workflow.openSecondaryViewer(message.payload.route, message.payload.studyUid)
          }
          else if (message['type'] == broadcast.PREV_STUDY_UID_MSG) {
            let windowUid = message['payload']
            if (this.$store.state.viewerWindows[windowUid] !== undefined) {
              // Need to inform secondary windows change in selected study UID and next/previous study UID
              //
              let studyUid = this.$store.getters.previousStudyUid(windowUid)
              if (studyUid != null) {
                workflow.openStudy(studyUid, workflow.TARGET_VIEWER, windowUid)
                .then(() => {
                  let payload = {
                    studyUid: studyUid,
                    windowUid: windowUid
                  }
                  this.$store.commit("changeSelectedStudyUids", payload)
                  broadcast.postSelectedStudy(workflow.TARGET_VIEWER, windowUid)

                  if ((this.$store.state.reportWindows[windowUid] !== undefined) && !this.$store.state.reportWindows[windowUid].closed){
                    workflow.openStudy(studyUid, workflow.TARGET_REPORT_WINDOW, windowUid)
                    .then(() => {
                      broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, windowUid)
                    })
                  }
                })
                .catch(err => {
                  this.displayToast(err.message, 'warning')
                })
              }
            }
          }
          else if (message['type'] == broadcast.REFRESH_WORKLIST_MSG) {
            // +TODO+ let windowUid = message['payload']
            webServices.readWorklist()
          }
          else if (message['type'] == broadcast.REPORT_WINDOW_CLOSED_MSG) {
            // Make sure window is actually closed (msg may be from older window)
            //
            let windowUid = message.payload.window_uid
            if (this.$store.state.reportWindows[windowUid] !== undefined) {
              this.$log.debug(`report window closed, study_uid=${message.payload.study_uid}`)
              let closeStudyUid = (message.payload.study_uid == '') ? workflow.getLockedStudyUid(workflow.TARGET_REPORT_WINDOW) : message.payload.study_uid
              workflow.closeStudy(closeStudyUid, workflow.TARGET_REPORT_WINDOW, windowUid)
              .then(() => {
                this.$log.debug("close study successful for report_window")
              })
              .catch(err => {
                this.displayToast(err.message, 'warning')
              })
              this.saveReportWindowBox()
              this.$store.commit('changeReportWindowsEditing', {
                'editing': false,
                'windowUid': windowUid
              })
              this.$store.commit('changeReportWindows', {
                'window': null,
                'windowUid': windowUid
              })
            }
          }
          else if (message['type'] == broadcast.REPORT_WINDOW_EDITING_MSG) {
            let windowUid = message['payload']
            this.$store.commit('changeReportWindowsEditing', {
              'editing': true,
              'windowUid': windowUid
            })
          }
          else if (message['type'] == broadcast.REPORT_WINDOW_READY_MSG) {
            let windowUid = message['payload']
            if ((this.$store.state.reportWindows[windowUid] !== undefined) && !this.$store.state.reportWindows[windowUid].closed) {
              // Need to inform report window current worklist and study UID
              //
              let studyUid = this.$store.getters.selectedStudyUid(windowUid)
              workflow.openStudy(studyUid, workflow.TARGET_REPORT_WINDOW, windowUid)
              .then(() => {
                broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, windowUid)
              })
              .catch(err => {
                this.displayToast(err.message, 'warning')
              })
            }
          }
          else if (message['type'] == broadcast.REPORT_WINDOW_RESIZE_MSG) {
            this.saveReportWindowBox(message['payload'])
          }
          else if (message['type'] == broadcast.REPORT_WINDOW_VIEWING_MSG) {
            this.$store.commit('changeReportWindowsEditing', {
              'editing': false,
              'windowUid': message['payload']
            })
          }
          else if (message['type'] == broadcast.STUDY_UPDATED_MSG) {
            // +TODO+ let windowUid = message['payload']
            studyStatusChanges.handleQuery(true)
          }
          else if (message['type'] == broadcast.VIEWER_WINDOW_CLOSED_MSG) {
            // Make sure active window was closed.
            //
            let windowUid = message.payload.window_uid
            if (this.$store.state.viewerWindows[windowUid] !== undefined) {
              this.$log.debug(`viewer window closed, study_uid=${message.payload.study_uid}`)
              let closeStudyUid = (message.payload.study_uid == '') ? workflow.getLockedStudyUid(workflow.TARGET_VIEWER) : message.payload.study_uid
              workflow.closeStudy(closeStudyUid, workflow.TARGET_VIEWER, windowUid)
              .then(() => {
                this.$log.debug("close study successful for viewer")
              })
              .catch(err => {
                this.displayToast(err.message, 'warning')
              })
              this.saveViewerWindowBox(windowUid)
              this.$store.commit('changeViewerWindows', {
                'window': null,
                'windowUid': windowUid
              })
            }
          }
          else if (message['type'] == broadcast.VIEWER_WINDOW_READY_MSG) {
            let windowUid = message['payload']
            if (this.$store.state.viewerWindows[windowUid] !== undefined) {
              // Need to inform viewer window current worklist and study UID
              //
              let studyUid = this.$store.getters.selectedStudyUid(windowUid)
              workflow.openStudy(studyUid, workflow.TARGET_VIEWER, windowUid)
              .then(() => {
                broadcast.postSelectedStudy(workflow.TARGET_VIEWER, windowUid)
              })
              .catch(err => {
                this.displayToast(err.message, 'warning')
              })

              // Keep associated report window in sync. +TODO+ Handle if in edit mode.
              //
              if ((this.$store.state.reportWindows[windowUid] !== undefined) && !this.$store.state.reportWindows[windowUid].closed) {
                let studyUid = this.$store.getters.selectedStudyUid(windowUid)
                workflow.openStudy(studyUid, workflow.TARGET_REPORT_WINDOW, windowUid)
                .then(() => {
                  broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, windowUid)
                })
                .catch(err => {
                  this.displayToast(err.message, 'warning')
                })
              }
            }
          }
          else if (message['type'] == broadcast.VIEWER_WINDOW_RESIZE_MSG) {
            let windowUid = message['payload']
            this.saveViewerWindowBox(windowUid)
          }
        }
      }
      catch(error) {
        this.$log.error("Error handling message: "+error.message)
      }
    },
    handleWindowBeforeUnload(event) {
      this.$log.debug('WindowBeforeUnload EVENT')
      if (this.$store.getters.isAnyReportOpenForEdit) {
        // Browser may present its own message.
        event.preventDefault();
        return event.returnValue = 'Report open in editor may have unsaved changes. Are you sure you want to close this window?'
      }

      // Remove all study locks
      //
      workflow.removeAllLocks()
    },
    handleWindowUnload(/*event*/) {
      this.$log.debug('WindowUnload EVENT')
      
      let uids = Object.keys(this.$store.state.reportWindows)
      for (let k = 0; k < uids.length; k++) {
        let uid = uids[k]
        try {
          if (this.$store.state.reportWindows[uid] !== undefined) {
            this.$store.state.reportWindows[uid].close()
            this.$store.commit('changeReportWindows', {
              'window': null,
              'windowUid': uid
            })
          }
        }
        catch(err) {
          this.$log.error(`Failed to close report window windowUid=${uid}: ${err.message}`);
        }
      }

      uids = Object.keys(this.$store.state.viewerWindows)
      for (let k = 0; k < uids.length; k++) {
        let uid = uids[k]
        try {
          if (this.$store.state.viewerWindows[uid] !== undefined) {
            this.$store.state.viewerWindows[uid].close()
            this.$store.commit('changeViewerWindows', {
              'window': null,
              'windowUid': uid
            })
          }
        }
        catch(err) {
          this.$log.error(`Failed to close viewer window windowUid=${uid}: ${err.message}`);
        }
      }

      this.$store.state.keycloak.logout()
    },
    saveReportWindowBox(windowUid) {
      if (this.$store.state.reportWindows[windowUid] !== undefined) {
        try {
          this.$store.state.reportWindowBox = {
            x: this.$store.state.reportWindows[windowUid].screenX,
            y: this.$store.state.reportWindows[windowUid].screenY,
            h: this.$store.state.reportWindows[windowUid].innerHeight,
            w: this.$store.state.reportWindows[windowUid].innerWidth
          }
        }
        catch {
          this.$log.warn("Unable to save report window box")
        }
      }
    },
    saveViewerWindowBox(windowUid) {
      if (this.$store.state.viewerWindows[windowUid] !== undefined) {
        try {
          this.$store.state.viewerWindowBox = {
            x: this.$store.state.viewerWindows[windowUid].screenX,
            y: this.$store.state.viewerWindows[windowUid].screenY,
            h: this.$store.state.viewerWindows[windowUid].innerHeight,
            w: this.$store.state.viewerWindows[windowUid].innerWidth
          }
        }
        catch {
          this.$log.warn("Unable to save viewer window box")
        }
      }
    }
  }
}
</script>
<style>
</style>